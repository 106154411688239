import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { User } from '../../models/user';
import { Router } from '@angular/router';
import { IbdSdkService } from '../common/ibd-sdk.service';
import {
  AuthenticateUserRequest,
  LogoutUserDeviceRequest,
} from '@ct-ibd/cmn-ibd-typescript-sdk';
import { CookieService } from 'ngx-cookie-service';
import { appConstants } from './../../constants/app.constants';
import { ProgressLoaderService } from '../loader/progressLoader.service';

@Injectable()
export class AuthenticationService {
  constructor(
    private router: Router,
    private spinner: ProgressLoaderService,
    private cookieService: CookieService,
    public ibdSdkService: IbdSdkService
  ) {}

  login(user: User): Observable<any> {
    const authenticateUserRequest = new AuthenticateUserRequest({
      email: user.email,
      password: user.password || '',
    });
    return from(
      this.ibdSdkService.ibd.authenticationManager.authenticateUser(
        authenticateUserRequest
      )
    );
  }

  saveAuthDetails(user: User): void {
    this.cookieService.set(appConstants.LOGGED_IN, 'true');
    if (user.rememberMe) {
      localStorage.setItem(appConstants.REMEMBER_ME, 'true');
    }
    this.router.navigateByUrl('');
  }

  public getRememberMe() {
    return localStorage.getItem(appConstants.REMEMBER_ME);
  }

  logoutUser(): Observable<any> {
    return from(this.ibdSdkService.loginCheck()).pipe(
      concatMap(() =>
        from(
          this.ibdSdkService.ibd.authenticationManager.logoutUserDevice(
            new LogoutUserDeviceRequest(
              <any>localStorage.getItem(appConstants.REFRESH_TOKEN)
            )
          )
        )
      )
    );
  }

  public getUserId() {
    return <any>localStorage.getItem(appConstants.USER_ID);
  }

  public getEmailId(): any {
    return localStorage.getItem(appConstants.EMAIL);
  }

  public logout() {
    this.spinner.show();
    this.logoutUser().subscribe(() => {
      this.spinner.hide();
      this.router.navigateByUrl('/login');
    });
  }
}
