import { appConstants } from './../../constants/app.constants';
import { Injectable } from '@angular/core';
import {
  Ibd,
  Configuration,
  ConfigurationDataReturnType,
} from '@ct-ibd/cmn-ibd-typescript-sdk';
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { from, Subject, Observable, of } from 'rxjs';
import { concatMap, takeWhile, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class IbdSdkService {
  ibd: Ibd;
  config: ConfigurationDataReturnType;
  router: Router;

  constructor(public store: LocalStorageService, router: Router) {
    this.config = new Configuration(
      environment.cmsSettings.appClientId,
      environment.cmsSettings.envName
    ).load();
    this.ibd = new Ibd(this.store, this.config);
    this.router = router;
  }

  loginCheck(): Observable<any> {
    if (this.ibd.authenticationManager.isAuthenticationExpired()) {
      return from(this.ibd.authenticationManager.refreshAuthentication()).pipe(
        takeWhile((responseData: any) => responseData != null),
        catchError((error: any) => {
          this.router.navigateByUrl('/login');
          return of(false);
        })
      );
    }
    return of(false);
  }
}
