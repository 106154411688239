import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UrlService {
  companyId = environment.cmsSettings.masterCompanyId;
  constructor() {}

  storeIdFromUrl(route: ActivatedRouteSnapshot): void {
    const id = route.paramMap.get('id');
    if (id && id !== this.companyId) {
      this.companyId = id;
      localStorage.setItem('id', id);
    }
  }

  urlWithCompany(url: string): string {
    return `/company/${this.companyId}${url}`;
  }
}
