import { NgModule } from '@angular/core';
import { InitialNavigation, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import {
  AuthGuardService,
  LoggedInUserService,
  URLGuardService,
} from './shared/services/auth/auth.guard.service';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/module/login/login.module').then((m) => m.LoginModule),
    canActivate: [LoggedInUserService],
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/module/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuardService],
    canActivateChild: [URLGuardService],
  },
  {
    path: '404',
    component: PageNotFoundComponent,
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled' as InitialNavigation,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
