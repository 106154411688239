import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { appConstants } from '../../constants/app.constants';
import { AuthenticationService } from './authentication.service';
import { IbdSdkService } from '../common/ibd-sdk.service';
import { UrlService } from '../url/url.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private route: Router,
    public authService: AuthenticationService,
    private cookieService: CookieService,
    private ibdSdkService: IbdSdkService
  ) {}

  async canActivate(): Promise<any> {
    if (
      !this.cookieService.check(appConstants.LOGGED_IN) &&
      this.authService.getRememberMe() != 'true'
    ) {
      this.route.navigateByUrl('/login');
      return false;
    } else if (this.cookieService.check(appConstants.LOGGED_IN)) {
      const isAuthExpired =
        await this.ibdSdkService.ibd.authenticationManager.isAuthenticationExpired();
      if (isAuthExpired) {
        // TODO: check following case
        const isRefreshExpired =
          this.ibdSdkService.ibd.authenticationManager.refreshAuthentication();
        if (isRefreshExpired == null) {
          //this.authService.logout();
          return false;
        }
      }
      return true;
    }
  }
}

@Injectable()
export class LoggedInUserService implements CanActivate {
  constructor(
    private route: Router,
    private cookieService: CookieService,
    private ibdSdkService: IbdSdkService
  ) {}

  async canActivate(): Promise<any> {
    if (
      this.cookieService.check(appConstants.LOGGED_IN) &&
      (await this.ibdSdkService.ibd.authenticationManager.loginCheck())
    ) {
      this.route.navigateByUrl('');
      return false;
    } else {
      return true;
    }
  }
}

@Injectable()
export class URLGuardService implements CanActivateChild {
  constructor(private urlService: UrlService) {}

  canActivateChild(route: ActivatedRouteSnapshot) {
    this.urlService.storeIdFromUrl(route);
    return true;
  }
}
