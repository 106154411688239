import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class ProgressLoaderService {
  constructor(public spinner: NgxSpinnerService) {}

  /**
   * Show the progress loader.
   */
  show(name?: string): void {
    setTimeout(() => {
      this.spinner.show(name);
    });
  }

  /**
   * Hide the progress loader
   */
  hide(name?: string): void {
    this.spinner.hide(name);
  }
}
