import {
  NgModule,
  APP_INITIALIZER,
  ErrorHandler,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { HttpClientModule } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import {
  LoggedInUserService,
  AuthGuardService,
  URLGuardService,
} from './shared/services/auth/auth.guard.service';
import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import { IbdSdkService } from './shared/services/common/ibd-sdk.service';
import { AuthenticationService } from './shared/services/auth/authentication.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProgressLoaderService } from './shared/services/loader/progressLoader.service';

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    CommonModule,
    NgbModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    LoggedInUserService,
    AuthGuardService,
    URLGuardService,
    ProgressLoaderService,
    AuthenticationService,
    IbdSdkService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
